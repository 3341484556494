<template>
  <a-table
    class="table-scroll"
    :pagination="{ pageSize: 10, hideOnSinglePage: true }"
    bordered
    :columns="columns"
    :data-source="data"
  >
    <p slot="week" slot-scope="text" class="text-center">{{text}}</p>
    <a-row type="flex" align="middle" slot="topic" slot-scope="text, record">
      <a-col :span="20">
        <a
          href
          style="white-space: normal; display: block; text-align:justify;"
          @click.prevent="toDetail(record.key)"
        >{{ text }}</a>
      </a-col>
      <a-col :span="4">
        <a-icon
          v-if="record.live"
          style="color: #1B55E3; font-size: 1.5em; display: block; text-align: right;"
          type="video-camera"
        />
      </a-col>
    </a-row>
    <div style="text-align: center; font-size: 1.5em;" slot="presence" slot-scope="text">
      <a-icon style="color:#41B883;" v-if="text==='present'" type="check-circle" />
      <div v-else class="circleUndone" />
    </div>
    <div slot="note" slot-scope="text, record" style="text-align: center; font-size: 1.5em">
      <a-icon v-if="record.notes" style="color:#41B883;" type="check-circle" />
      <div v-else class="circleUndone" />
    </div>
    <div slot="status" slot-scope="text, record" style="text-align: center;">
      <template v-if="record.assignment_status === 'Submitted'">
        <div v-if="record.tugas_nilai !== ''" style="display: flex; justify-content: center;">
          <div
            :style="{
          backgroundColor: color(record.tugas_nilai)
        }"
            class="circleGrade"
          />
          <b>{{record.tugas_nilai}}</b>
        </div>
        <div style="color: #41B883;" v-else>{{record.assignment_status}}</div>
      </template>
      <template v-else>
        <div v-if="record.tipe_assignment" style="color: #FF8811;">{{record.assignment_status}}</div>
        <div v-else style="color: #808084;">No Assignment</div>
      </template>
    </div>
  </a-table>
</template>
<script>
import moment from 'moment'

export default {
  props: ['listTable'],
  data() {
    return {
      data: [],
      columns: [
        {
          title: 'Week',
          dataIndex: 'week',
          key: 'week',
          // width: '12%',
          sorter: (a, b) => a.week - b.week,
          className: 'text-center',
          scopedSlots: { customRender: 'week' },
        },
        {
          title: 'Session Time',
          dataIndex: 'sessionTime',
          sorter: (a, b) => moment(b.sessionTime, 'DD MMM YYYY, HH:mm') - moment(a.sessionTime, 'DD MMM YYYY, HH:mm'),
          key: 'sessionTime',
          // width: '18%',
        },
        {
          title: 'Topic',
          dataIndex: 'topic',
          key: 'topic',
          // width: '32%',
          ellipsis: true,
          scopedSlots: { customRender: 'topic' },
        },
        {
          title: 'Presence',
          dataIndex: 'presence',
          key: 'presence',
          className: 'text-center',
          // width: '10%',
          ellipsis: true,
          scopedSlots: { customRender: 'presence' },
        },
        {
          title: 'Note',
          dataIndex: 'note',
          key: 'note',
          className: 'text-center',
          // width: '10%',
          ellipsis: true,
          scopedSlots: { customRender: 'note' },
        },
        {
          title: 'Assignment Status',
          dataIndex: 'status',
          className: 'text-center',
          key: 'status',
          ellipsis: true,
          scopedSlots: { customRender: 'status' },
        },
      ],
    }
  },
  created() {
    // this.listTable.forEach(list => {
    //   if (moment().locale('id') > moment(`${list.tanggal_sesi} ${list.slot.model_jadwal.jam_mulai}`, 'YYYY-MM-DD HH:mm')) {
    //     this.data.push({
    //       key: list.id,
    //       session: list.id,
    //       sessionTime: `${moment(list.tanggal_sesi, 'YYYY-MM-DD').format('DD MMM YYYY')}, ${moment(list.slot.model_jadwal.jam_mulai, 'HH:mm').format('HH:mm')}`,
    //       topic: list.topik.brief,
    //       live: list.zoom_setting,
    //       presence: list.attendances.length && list.attendances[0].status_attendance,
    //       notes: list.attendances.length && list.attendances[0].notes ? list.attendances[0].notes : '',
    //       assignment_status: list.attendances.length && list.attendances[0].tugas_nilai ? 'Submitted' : 'Waiting to submit',
    //       tugas_nilai: list.attendances.length && list.attendances[0].tugas_nilai ? list.attendances[0].tugas_nilai : '',
    //     })
    //   }
    // })
    // console.log(this.listTable)

    this.data = this.listTable.map(list => {
      return {
        key: list.id,
        week: list.week.week,
        session: list.id,
        sessionTime: `${moment(list.tanggal_sesi, 'YYYY-MM-DD').format('DD MMM YYYY')}, ${moment(list.slot.model_jadwal.jam_mulai, 'HH:mm').format('HH:mm')}`,
        topic: list.topik.nama,
        live: list.zoom_setting,
        presence: list.attendances.length && list.attendances[0].status_attendance,
        notes: list.attendances.length && list.attendances[0].notes ? list.attendances[0].notes : '',
        assignment_status: list.attendances.length && list.attendances[0].submit_time ? 'Submitted' : 'Waiting to submit',
        tugas_nilai: list.attendances.length && list.attendances[0].tugas_nilai ? list.attendances[0].tugas_nilai : '',
        tipe_assignment: list.tipe_assignment,
      }
    })

    // console.log(this.data)
  },
  methods: {
    color(nilai) {
      if (nilai < 35) {
        return '#FF0000'
      } else if (nilai >= 35 && nilai < 70) {
        return 'orange'
      } else if (nilai >= 70 && nilai <= 100) {
        return '#41B883'
      } else {
        return '#FF0000'
      }
    },
    async toDetail(idSesi) {
      await this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Schedule Student'],
      })
      await this.$store.commit('menu/SET_STATE', {
        currentDetailScheduleMenu: ['assignments'],
      })
      await this.$router.push({ name: 'Detail Schedule Student', params: { id: idSesi } })
    },
  },
}
</script>
<style lang="scss">
.table-scroll {
  .circleUndone {
    width: 1em;
    height: 1em;
    border: 2px solid #707788;
    border-radius: 100%;
    margin: 0 auto;
  }

  .circleGrade {
    width: 0.8em;
    height: 0.8em;
    border-radius: 100%;
    float: left;
    margin-top: 3%;
    margin-right: 2%;
  }

  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;
    table {
      @media (max-width: 769px) {
        width: auto;
      }
    }
  }
}

.table-scroll {
  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;
    table {
      @media (max-width: 769px) {
        width: auto;
      }
    }
  }
}
</style>
