<template>
  <a-card
    class="card-AIP"
    @click.prevent="toDetail(AIPSubject.id)"
    :style="{
      'border-left-width': '8px',
      'border-left-color': cardColor,
      width: !isDashboard || isMobile ? '100%' : '95%',
    }"
  >
    <div slot="title">
      <h3>{{ AIPSubject.slot.mata_pelajaran.nama }}</h3>
      <div class="additionalAssignmentText">
        Sess. {{ AIPSubject.week.week }}/{{ totalWeeks }}
        {{ AIPSubject.topik.nama }}
      </div>
    </div>
    <a slot="extra" href="#"
      ><a-icon
        :type="iconAssignmentType"
        :style="{
          borderRadius: '4px',
          color: '#1B55E3',
          padding: '5px',
          background: `rgba(234, 240, 252, 0.9)`,
          fontSize: '1.5rem',
        }"
    /></a>
    <a-row>
      <a-col :span="12">
        <p
          :style="{
            color: cardColor,
            'font-size': '1rem!important',
          }"
        >
          <b>{{
            AIPSubject.attendances.length &&
            AIPSubject.attendances[0].submit_time &&
            AIPSubject.attendances[0].submit_time !== ""
              ? "Submitted"
              : "Waiting to Submit"
          }}</b>
        </p>
      </a-col>
      <a-col :span="12">
        <p
          :style="{
            textAlign: 'right',
            color: cardColor,
            fontSize: '1rem!important',
          }"
        >
          {{ deadline }} <a-icon type="hourglass" />
        </p>
      </a-col>
    </a-row>
    <br />
    <p
      class="boxElipsis"
      :style="{
        fontSize: '1rem!important',
      }"
    >
      <span v-html="AIPSubject.tugas_deskripsi"></span>
    </p>
  </a-card>
</template>
<script>
import moment from 'moment'

export default {
  props: ['AIPSubject', 'totalWeeks', 'isDashboard'],
  data() {
    return {
    }
  },
  methods: {
    toDetail(idSesi) {
      this.$router.push({ name: 'Detail Schedule Student', params: { id: idSesi } })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Schedule Student'],
        currentDetailScheduleMenu: ['assignments'],
      })
    },
  },
  computed: {
    iconAssignmentType() {
      if (this.AIPSubject.tipe_assignment === 'Essay') {
        return 'align-left'
      } else if (this.AIPSubject.tipe_assignment === 'File Upload') {
        return 'paper-clip'
      } else if (this.AIPSubject.tipe_assignment === 'Multiple Choice') {
        return 'unordered-list'
      } else {
        return 'save'
      }
    },
    deadline() {
      return moment(this.AIPSubject.tugas_deadline2).locale('id').format('DD MMMM YYYY')
    },
    cardColor() {
      const tglDueDate = this.AIPSubject.tugas_deadline2
      const submitTime = this.AIPSubject.attendances.length ? this.AIPSubject.attendances[0].submit_time : null
      const hariIni = moment()
      const range = hariIni.diff(tglDueDate, 'days')
      if (submitTime) {
        return '#41B883'
      } else {
        if (range <= 2) {
          return '#FF0000'
        } else {
          return '#FF0000'
        }
      }
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
  },
}
</script>
<style lang="scss">
.card-AIP {
  cursor: pointer;
  box-shadow: 0px 0px 10px #0419301a;
  width: 100%;
  min-height: 225px;
  border-radius: 6px;
  margin-bottom: 5%;
  min-height: 230px;

  .boxElipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .additionalAssignmentText {
    color: #707788;
    font-family: "Mukta", "sans-serif";
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 2%;
  }
}

.card-AIP:hover {
  transform: translate(0, -5px);
  box-shadow: 0px 0px 20px #0419301a;
}
</style>
